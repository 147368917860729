import * as React from "react"
import Navbar from "../components/Navbar";
import Gallery from "../components/Gallery";
import Footer from "../components/Footer";
import { mainContainer } from "./index.module.css";

const IndexPage = () => {
  return (
   <div className={mainContainer}>
     <Navbar />
     <br />
     <Gallery />
     <Footer />
   </div>
  )
}

export default IndexPage
export const Head = () => <title>Dyspatch Block Gallery</title>